




















































import {axiosPost} from '@/utils/axiosWrapper';
import Vue from 'vue';

function validEmail(email: string) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

interface Mail {
  toMail: string;
  fromMail: string;
  subject: string;
  message: string;
}

export default Vue.extend({
  name: 'E-Mail',
  props: {
    emails: {
      type: [],
      default: () => []
    },
    serverAddress: String,
    websiteName: {
      type: String,
      default: "this website"
    },
    infoText: {
      type: String,
      default: ""
    },

    defaultFrom: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      toMail: "",
      fromMail: "",
      subject: "",
      message: "",

      sendToAll: false,

      mailArray: [] as Mail[],
      progress: -1,
      progressMax: 1
    }
  },
  methods: {
    clearAll: function () {
      this.toMail = ""
      this.fromMail = this.defaultFrom
      this.subject = ""
      this.message = ""
      this.mailArray = []

    },
    sendMail: function () {
      if (!validEmail(this.fromMail)) {
        alert(this.fromMail + 'is not an E-Mail.')
        return
      }
      if (!this.sendToAll) {
        if (!validEmail(this.toMail)) {
          console.warn(this.toMail + 'is not an E-Mail.')
        }
        this.progressMax = 2
        this.progress = 0
        this.mailArray.push({
          toMail: this.toMail,
          fromMail: this.fromMail,
          subject: this.subject,
          message: this.message.replaceAll("\n", "<br />")
        })
        this.progress++
      } else {
        this.progressMax = this.emails.length * 2
        this.progress = 0
        this.emails.forEach(u => {
          this.mailArray.push({
            toMail: u,
            fromMail: this.fromMail,
            subject: this.subject,
            message: this.message.replaceAll("\n", "<br />")
          })
          this.progress += 1
        })
      }
      this.mailArray.forEach((m, index) => {
        axiosPost(this.serverAddress, m)
            .then(() => {
              this.progress += 1

              console.log("Sending Email", index + 1, "from ", this.mailArray.length)
            })
            .catch(err => console.warn(err))
            .finally(() => {
              if (index === this.mailArray.length - 1) this.doneSending()
            })
      })
    },
    doneSending: function () {

      this.progress = -1
      this.progressMax = 1
      this.clearAll()
      console.log('E-Mails sent.')
      alert('E-Mails sent.')
    }
  },
  mounted() {
    this.fromMail = this.defaultFrom
  }
});
